































































































































































































import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      appTitle: 'guavaVet',
      sidebar: false,
      practices: [] as { name: string; uid: string }[]
    };
  },
  computed: {
    hide() {
      return this.$route.path === '/signIn' || this.$route.path === '/register';
    },
    hasLoadedUser() {
      if (this.$store.state.currentUser !== null) {
        (this as any).$intercom.boot({
          // eslint-disable-next-line @typescript-eslint/camelcase
          user_id: this.$store.state.currentUser.uid,
          name: this.$store.state.currentUser.name,
          email: this.$store.state.currentUser.email
        });
        // (this as any).$intercom.show();
      }

      return (
        this.$store.state.currentUser !== null ||
        this.$route.path === '/signIn' ||
        this.$route.path === '/register'
      );
    }
  },
  methods: {
    signOut() {
      firebase.auth().signOut();
      location.reload();
    }
  }
});
