const FIREBASE = {
  firebaseConfig:
    process.env.NODE_ENV === 'production'
      ? {
          apiKey: 'AIzaSyCCsa2TNaFFtFRFyD4XiGb-AzXDSMiKOaE',
          authDomain: 'guava-vet-production.firebaseapp.com',
          databaseURL: 'https://guava-vet-production.firebaseio.com',
          projectId: 'guava-vet-production',
          storageBucket: 'guava-vet-production.appspot.com',
          messagingSenderId: '593186038061',
          appId: '1:593186038061:web:daa9a9049cedfc5ad0b3a4',
          measurementId: 'G-5SD96CTCTY'
        }
      : {
          apiKey: 'AIzaSyAXlqfX0BjuHXQEdJebAf3xcRjMYC9mmds',
          authDomain: 'guava-vet-development.firebaseapp.com',
          databaseURL: 'https://guava-vet-development.firebaseio.com',
          projectId: 'guava-vet-development',
          storageBucket: 'guava-vet-development.appspot.com',
          messagingSenderId: '186249272471',
          appId: '1:186249272471:web:d4d9d5ccea284af0fd9dd2',
          measurementId: 'G-2YR114DZ0N'
        }
};

// if (location.hostname === 'localhost') {
//   FIREBASE.firebaseConfig.databaseURL = 'http://localhost:4000?ns=emulatorui';
// }

const ALGOLIA = {
  APP_ID: '657N7T0ODI',
  SEARCH_KEY: 'f0ee5f1a165622057dd9af6ee8db479c',
  INDEXES: {
    POSITIONS:
      process.env.NODE_ENV === 'production'
        ? 'prod_positions'
        : 'dev_positions',
    REQUESTS:
      process.env.NODE_ENV === 'production' ? 'prod_requests' : 'dev_requests',
    USERS: process.env.NODE_ENV === 'production' ? 'prod_users' : 'dev_users',
    PRACTICES:
      process.env.NODE_ENV === 'production' ? 'prod_practices' : 'dev_practices'
  }
};

const URLS = {
  googlePlaces: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_PLACES_API_KEY}&libraries=places&callback=placesInitialized`
};

const ACADEMIC_INSTITUTIONS = {
  names: [
    'Not Listed',
    'Harper & Keele Veterinary School',
    'Royal Veterinary College (RVC)',
    'University of Aberystwyth',
    'University of Bristol',
    'University of Cambridge',
    'University of Edinburgh [Royal (Dick) School of Veterinary Studies]',
    'University of Glasgow',
    'University of Liverpool',
    'University of Nottingham',
    'University of Surrey School of Veterinary Medicine',
    'University College Dublin',
    'University of Pretoria',
    'Charles Sturt University',
    'James Cook University',
    'Murdoch University',
    'University of Adelaide',
    'University of Melbourne',
    'University of Queensland',
    'University of Sydney',
    'Massey University'
  ]
};

const ACCREDITATION_INSTITUTIONS = {
  names: [
    'Not Listed',
    'RCVS',
    'SAVC',
    'Veterinary Practitioners Board of New South Wales',
    'Veterinary Surgeons Board of Western Australia',
    'Veterinary Board of Tasmania',
    'Veterinary Surgeons Board of Queensland',
    'ACT Veterinary Practitioners Board',
    'The Veterinary Practitioners Registration Board of Victoria',
    'Veterinary Board of the Northern Territory',
    'Veterinary Council of New Zealand'
  ]
};

export default {
  FIREBASE,
  ALGOLIA,
  ACADEMIC_INSTITUTIONS,
  ACCREDITATION_INSTITUTIONS,
  URLS
};
