import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// $primary-custom: #d65365;
// $primary-custom-light: #fd8491;
// $primary-custom-dark: #b71a39;

// $secondary-custom: #4f6c2c;
// $secondary-custom-light: #7c9a55;
// $secondary-custom-dark: #234106;

// $text-light: #767676;

// $text-dark: #1f1f1f;

// $darkBlue: #293842;

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#d65365',
        primarylight: '#fd8491',
        secondary: '#4f6c2c',
        accent: '#b71a39',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  }
});
