import Vue from 'vue';
import Vuex from 'vuex';
import 'firebase/auth';
import { User } from '../models/user.model';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null as null | User,
    firebaseUser: null as null | firebase.default.User,
    isLoading: false
  },
  mutations: {
    updateFirebaseUser(state, user: firebase.default.User) {
      state.firebaseUser = user;
    },
    updateCurrentUser(state, user: User) {
      state.currentUser = user;
    },
    updateIsLoading(state, isLoading: boolean) {
      state.isLoading = isLoading;
    }
  },
  actions: {},
  modules: {}
});
