import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';

const isScriptLoaded = (url: string) => {
  const scripts = document.getElementsByTagName('script');
  for (let i = scripts.length; i--; ) {
    if (scripts[i].src == url) return true;
  }
  return false;
};

const undefinedToNull = (object: any): object => {
  const cloneObjUpdate = { ...object };
  Object.keys(object).forEach(key => {
    if (cloneObjUpdate[key] === undefined) {
      cloneObjUpdate[key] = null;
    }
  });
  return cloneObjUpdate;
};
const timestampToDate = (timestamp: any): Date | null => {
  if (timestamp) {
    return new firebase.firestore.Timestamp(
      timestamp._seconds ?? timestamp.seconds,
      timestamp._nanoseconds ?? timestamp.nanoseconds
    ).toDate();
  }
  return null;
};

const timestampToDateTime = (
  timestamp: firebase.firestore.Timestamp
): string => {
  return moment(timestamp.toDate()).format('HH:mm DD-MM-YYYY');
};

const getYearsBetweenTimestamps = (
  earlierDate: firebase.firestore.Timestamp,
  laterDate: firebase.firestore.Timestamp
): number | null => {
  if (earlierDate && laterDate) {
    const earlyDate = moment(timestampToDate(earlierDate));
    const lateDate = moment(timestampToDate(laterDate));
    const years = lateDate.diff(earlyDate, 'years');
    return years;
  }
  return null;
};

export default {
  isScriptLoaded,
  undefinedToNull,
  timestampToDate,
  timestampToDateTime,
  getYearsBetweenTimestamps
};
