import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/About.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/signIn',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/practiceProfile',
    name: 'PracticeProfile',
    component: () => import('../views/PracticeProfile.vue')
  },
  {
    path: '/positions',
    name: 'Positions',
    component: () => import('../views/Positions.vue')
  },
  {
    path: '/editTeamMember',
    name: 'EditTeamMember',
    component: () => import('../views/EditTeamMember.vue')
  },
  {
    path: '/candidateProfile',
    name: 'CandidateProfile',
    component: () => import('../views/CandidateProfile.vue')
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('../views/Requests.vue')
  },
  {
    path: '/candidates',
    name: 'Candidates',
    component: () => import('../views/Candidates.vue')
  },
  {
    path: '/candidates/candidate',
    name: 'CandidateView',
    component: () => import('../views/CandidateView.vue')
  },
  {
    path: '/requests/requestView',
    name: 'RequestView',
    component: () => import('../views/RequestView.vue')
  },
  {
    path: '/candidatePositions',
    name: 'CandidatePositions',
    component: () => import('../views/CandidatePositions.vue')
  },
  {
    path: '/candidatePositions/position',
    name: 'PositionView',
    component: () => import('../views/PositionView.vue')
  },
  {
    path: '/positions/position/edit',
    name: 'EditPosition',
    component: () => import('../views/EditPosition.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/switchPractice',
    name: 'SwithPractice',
    component: () => import('../views/SwitchPractice.vue')
  },
  {
    path: '/newPractice',
    name: 'NewPractice',
    component: () => import('../views/NewPractice.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
