/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/auth';
import constants from '../src/constants/constants';
import { User } from '../src/models/user.model';
import VuetifyConfirm from 'vuetify-confirm';
import helpers from '@/helpers/helpers';
import VueCurrencyFilter from 'vue-currency-filter';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import './filters/filters';
import Lightbox from '@morioh/v-lightbox';
import '@morioh/v-lightbox/dist/lightbox.css';
import VueChatScroll from 'vue-chat-scroll';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VueYouTubeEmbed from 'vue-youtube-embed';
import VueIntercom from 'vue-intercom';
import VueCalendly from 'vue-calendly';

Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID });
Vue.use(VueYouTubeEmbed);
Vue.use(VueChatScroll);
Vue.use(CoolLightBox);
Vue.use(Lightbox);
Vue.use(VueCalendly);
Vue.use(VueTelInputVuetify, {
  vuetify
});

const HelpersPlugin = {
  install() {
    Vue.prototype.$helpers = helpers;
  }
};
Vue.use(VueCurrencyFilter, [
  {
    name: 'usd',
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: ''
  },
  {
    name: 'gbp',
    symbol: '£',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: ''
  },
  {
    name: 'eur',
    symbol: '€',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: ''
  },
  {
    name: 'zar',
    symbol: 'R',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: ''
  },
  {
    name: 'aud',
    symbol: 'AUS$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: ''
  },
  {
    name: 'nzd',
    symbol: 'NZ$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: ''
  }
]);
Vue.use(HelpersPlugin);
Vue.use(VuetifyConfirm, { vuetify });
Vue.config.productionTip = false;
firebase.initializeApp(constants.FIREBASE.firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
// if (location.hostname === 'localhost') {
//   db.settings({
//     host: 'localhost:8081',
//     ssl: false
//   });
//   firebase.auth().useEmulator('http://localhost:9099/');
//   firebase.functions().useEmulator('localhost', 5001);
// }

let userListener: (() => void) | null = null;

const authListener = firebase.auth().onAuthStateChanged(async user => {
  if (
    !user &&
    router.currentRoute.name !== 'SignIn' &&
    router.currentRoute.name !== 'Register'
  ) {
    authListener();
    router.push({ name: 'SignIn' });
  }
  store.commit('updateFirebaseUser', user);
  if (user && !userListener) {
    userListener = db
      .collection('users')
      .doc(user.uid)
      .onSnapshot(doc => {
        if (doc.data()) {
          const userToUpdate = doc.data() as User;
          store.commit('updateCurrentUser', userToUpdate);
        }
      });
  }
});

firebase
  .firestore()
  .enablePersistence()
  .catch(err => {
    if (err.code === 'failed-precondition') {
      console.log(err);
    } else if (err.code === 'unimplemented') {
      console.log('persistence not supported');
    }
  });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

declare global {
  interface Window {
    placesInitialized: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}
