import Vue from 'vue';
import moment from 'moment';
import { Timestamp } from '@firebase/firestore-types';
import helpers from '@/helpers/helpers';
import firebase from 'firebase';

Vue.filter('timestampToDate', (timestamp: any) => {
  if (timestamp) {
    return moment
      .unix(timestamp.seconds ?? timestamp._seconds)
      .format('DD/MM/YYYY');
  }
});

Vue.filter('getYearsSinceTimestamp', (earlierDate: Timestamp):
  | number
  | null => {
  if (earlierDate) {
    const earlyDate = moment(helpers.timestampToDate(earlierDate));
    const lateDate = moment(Date());
    const years = lateDate.diff(earlyDate, 'years');
    return years;
  }
  return null;
});

Vue.filter(
  'timestampToDateTime',
  (timestamp: firebase.firestore.Timestamp): string => {
    return moment(timestamp.toDate()).format('ddd HH:mm DD-MM-YYYY');
  }
);
